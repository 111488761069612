body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Euclid Flex Trial";
  src: url('./fonts/EuclidFlexBold.woff') format('woff');
}

@font-face {
  font-family: "GT America Trial";
  src: url("./fonts/gt-america-standard-regular.woff") format('woff');
}

@font-face {
  font-family: 'GT Pressura Mono';
  src: url('./fonts/GT-Pressura-Mono-Regular.woff') format('woff');
}

@font-face {
  font-family: "GT America Light";
  src: url("./fonts/gt-america-light.woff");
}